// extracted by mini-css-extract-plugin
export var container = "AddComplaint-module--container--PBHFk";
export var header = "AddComplaint-module--header--dsKQj";
export var content = "AddComplaint-module--content--N1eGc";
export var form = "AddComplaint-module--form--KHhAO";
export var form__checkbox = "AddComplaint-module--form__checkbox--BRVc6";
export var form__group = "AddComplaint-module--form__group--n8qCM";
export var form__group__files = "AddComplaint-module--form__group__files--SHbN7";
export var form__group__input = "AddComplaint-module--form__group__input--Ki0LY";
export var form__group__select = "AddComplaint-module--form__group__select--vdtdQ";
export var textarea = "AddComplaint-module--textarea--zgfrO";
export var submit = "AddComplaint-module--submit---BtX9";