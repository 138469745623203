import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { navigate } from "gatsby";
import React, { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";

import BackIcon from "../../images/back_arrow_icon@2x.png";
import { CreateComplaint } from "../../Interfaces/Complaint";
import { GetResidancesQuery, Tower } from "../../Interfaces/ParkingArea";
import {
  CreateComplaintQuery,
  GetComplaintCategory,
} from "../../Query/Complaints";
import { GetCustomTowerQuery } from "../../Query/ParkingArea";
import Logger from "../../utils/Logger";
import Server from "../../utils/Server";
import { UploadMedia } from "../../utils/UploadFile";

import Loader from "../Loader";

import * as AddComplaintStyle from "./AddComplaint.module.scss";

interface Props {
  areaId: string;
}

interface Inputs {
  residence: string;
  isUrgent: boolean;
  category: string;
  description: string;
  files?: FileList;
}

interface Category {
  id: string;
  code: string;
  name: string;
}

const GetTowers = gql`
  query GetAreaResidences($parkingArea: String) {
    residences(parkingArea: $parkingArea, itemsPerPage: 200) {
      collection {
        id
        towerName
      }
    }
  }
`;

const GetFlats = gql`
  query GetFlats($towerName: String, $parkingArea: String) {
    residences(
      towerName: $towerName
      parkingArea: $parkingArea
      itemsPerPage: 200
    ) {
      collection {
        id
        flatName
      }
    }
  }
`;

const Index = ({ areaId }: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [
    createComplaint,
    { error: createComplaintError, loading: createComplaintLoading },
  ] = useMutation<CreateComplaint>(CreateComplaintQuery);

  const { data, loading, error } = useQuery<{
    custom_collection_queryResidences: { collection: Tower[] };
  }>(GetCustomTowerQuery, {
    variables: {
      parkingArea: `/parking_areas/${areaId}`,
    },
  });

  const {
    data: categorys,
    loading: categoryLoading,
    error: categoryError,
  } = useQuery<{ complaintCategories: { collection: Category[] } }>(
    GetComplaintCategory
  );

  const [getFlats, { data: flats, loading: flatLoading }] =
    useLazyQuery<GetResidancesQuery>(GetFlats, {
      fetchPolicy: "network-only",
    });

  const { register, handleSubmit } = useForm();

  const uploadComplaintMedia = (file: File, complaintId: string) => {
    return new Promise((resolve, reject) => {
      UploadMedia({
        name: file?.name,
        path: file,
        type: file?.type,
      })
        .then((res) => {
          Server.get(
            `/residence/complaint/${complaintId?.split("/")[2]}/media`,
            {
              keyname: res.key,
              mediaType: "PHOTO",
            }
          )
            .then((res) => {
              resolve(res);
            })
            .catch((err) => {
              reject(err);
            });
        })
        .catch((err) => reject(err));
    });
  };

  const onHandleSubmit: SubmitHandler<Inputs> = (d) => {
    const files: File[] = [...Array(d.files?.length)].map(
      (_, i) => d.files?.item(i) as File
    );

    createComplaint({
      variables: d,
    })
      .then((response) => {
        const filesPromises = files.map((file) => {
          return new Promise((resolve, reject) => {
            uploadComplaintMedia(
              file,
              response.data?.createResidenceComplaint.residenceComplaint.id ||
                ""
            )
              .then((res) => {
                resolve(res);
              })
              .catch((err) => reject(err));
          });
        });

        setIsLoading(true);

        Promise.all(filesPromises)
          .then(() => {
            navigate(-1);
          })
          .catch((err) => {
            Logger.showError(err);
          })
          .finally(() => setIsLoading(false));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    const err = categoryError || createComplaintError || error;
    if (err) Logger.showError(err);
  }, [createComplaintError, error, categoryError]);

  return (
    <>
      <div className={AddComplaintStyle.container}>
        <div className={AddComplaintStyle.header}>
          <button onClick={() => navigate(-1)}>
            <img src={BackIcon} alt="back" width={28} height={28} />
          </button>
        </div>
        <div className={AddComplaintStyle.content}>
          <form
            className={AddComplaintStyle.form}
            onSubmit={handleSubmit(onHandleSubmit)}
          >
            <div className={AddComplaintStyle.form__group}>
              <label htmlFor="towerName">Tower Name</label>
              <select
                className={AddComplaintStyle.form__group__select}
                id="towerName"
                onChange={(e) => {
                  getFlats({
                    variables: {
                      parkingArea: `/parking_areas/${areaId}`,
                      towerName: e.target.value,
                    },
                  }).catch((err) => {
                    Logger.showError(err);
                  });
                }}
              >
                <option value="">Select One</option>
                {data?.custom_collection_queryResidences?.collection.map(
                  ({ id, towerName }, i) => (
                    <option value={towerName} key={`${id}/${i}/${towerName}`}>
                      {towerName}
                    </option>
                  )
                )}
              </select>
            </div>
            <div className={AddComplaintStyle.form__group}>
              <label htmlFor="residence">Flat Name</label>
              <select
                className={AddComplaintStyle.form__group__select}
                id="residence"
                {...register("residence")}
                required
              >
                <option value="">Select One</option>
                {flats?.residences?.collection.map(({ id, flatName }, i) => (
                  <option value={id} key={`${id}/${flatName}`}>
                    {flatName}
                  </option>
                ))}
              </select>
            </div>
            <div className={AddComplaintStyle.form__group}>
              <label htmlFor="category">Category</label>
              <select
                className={AddComplaintStyle.form__group__select}
                id="category"
                {...register("category")}
                required
              >
                <option value="">Select One</option>
                {categorys?.complaintCategories?.collection.map(
                  ({ id, name }, i) => (
                    <option value={id} key={`${id}/${i}`}>
                      {name}
                    </option>
                  )
                )}
              </select>
            </div>
            <div
              className={`${AddComplaintStyle.form__group} ${AddComplaintStyle.textarea}`}
            >
              <label htmlFor="description">Complaint Description</label>
              <textarea
                id="description"
                {...register("description")}
                placeholder="Type Complaint Description"
                rows={6}
                className={AddComplaintStyle.form__group__select}
                required
              />
            </div>
            <div
              className={`${AddComplaintStyle.form__group} ${AddComplaintStyle.form__group__files}`}
            >
              <label htmlFor="file">Upload Images</label>
              <input {...register("files")} multiple type="file" id="file" />
            </div>
            <div className={AddComplaintStyle.form__checkbox}>
              <input {...register("isUrgent")} type="checkbox" id="isUrgent" />
              <label htmlFor="isUrgent">Is It Urgent?</label>
            </div>
            <div className={AddComplaintStyle.submit}>
              <button type="submit">Submit</button>
            </div>
          </form>
        </div>
      </div>
      <Loader
        isLoading={
          loading ||
          isLoading ||
          categoryLoading ||
          flatLoading ||
          createComplaintLoading
        }
      />
    </>
  );
};

export default Index;
